@use '../styles/global/Vars.scss';

.Bottom {
	width: calc(100% - 32px);
	position: absolute;
	bottom: calc(0px + env(keyboard-inset-height, 0px));
	margin-bottom: 12px;
	text-align: center;
}

.hiddenElement {
	display: none;
}

.root {
	min-height: -webkit-fill-available;
	height: 100%;
}

body {
	height: 100%;
}


*,
*:before,
*:after {
	box-sizing: border-box;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	&:link {
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
	}
}
body {
	line-height: 1;
}
ol, ul, li {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
#root {
	min-height: auto;
	background: rgb(220, 220, 220);
	min-height: 100vh; /* Fallback */
	min-height: 100dvh;
}



input, select, textarea, button, ul{
    -moz-box-shadow:none;
	box-shadow: none;
	outline: 0;
	border: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.InputLabel {
	position: absolute;
		display: block;
		transform-origin: left top 0px;
		overflow: hidden;
		left: 10px;
		top: 0px;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: calc(100% - 24px);
		background: white;
		padding: 0 4px;
		z-index: 1;
		transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
			transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
			max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		pointer-events: none;
		color: var(--color-grey-2);
		transform: translate(14px, 19px) scale(1);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		border-radius: 5px;
	
		&:hover {
			background-color: #c4c4c4;
		}
	
		&:focus {
			box-shadow: 0 0 0 2px black;
		}
	
		&[data-placeholder] {
			color: var(--color-grey-2);
		}
	
		&[data-active='true'] {
			color: var(--color-grey-2);
			transform: translate(14px, -5px) scale(0.875);
			top: -2px;
		}
}